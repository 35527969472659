import request from '@/utils/request'

// 获取设备列表
export function getDeviceList(data) {
  return request({
    method: 'post',
    path: 'webapi/waterEquipment/equipmentList',
    dataType: 'json',
    data
  })
}

// 获取设备详情
export function getDeviceDetail(data) {
  return request({
    method: 'get',
    path: 'webapi/waterEquipment/getEquipment',
    data
  })
}

// 获取设备数据
export function getDeviceData(data) {
  return request({
    method: 'get',
    path: 'webapi/waterEquipment/getEquipmentData',
    data
  })
}


// 保存设备信息
export function saveDevice(data) {
  return request({
    method: 'post',
    path: 'deviceInfo/saveDevicesFiles',
    data
  })
}