<template>
  <div>
    <Header title="实施工况" color="#666" />
    <van-tabs v-model="active" color="#1989fa" title-active-color="#1989fa">
      <van-tab title="终端参数">
        <van-cell-group class="mgt10">
          <van-cell
            v-for="(item, index) in paramList"
            :key="index"
            :title="item.name"
            :label="parseTime(item.sampleTime)"
            center
          >
            <template #default>
              <div class="value">
                <span>{{item.sampleNum + (item.unit ? item.unit : '')}}</span>
              <!-- 标准值 -->
              <span class="gray" v-if="item.mainName=='微功耗无线智能监测终端-1' ||item.mainName=='微功耗无线智能监测终端-2'">
                {{hedaolist[index]}}
                </span>
            <span class="gray" v-if="jingai!=-1">
                {{jingailist[index]}}
            </span>
            <span class="gray" v-if="yushuijin!=-1">
              {{yushuijinlist[index]}}
            </span>
            <span class="gray" v-if="wushuijin!=-1">
              {{wushuijinlist[index]}}
            </span>
              </div>
              </template>
          </van-cell>
        </van-cell-group>
      </van-tab>
      <van-tab title="告警信息">
        <van-empty
          v-if="gaojingList.length == 0"
          description="暂无数据"
        ></van-empty>
        <van-cell
          v-for="(item, index) in gaojingList"
          :key="index"
          :to="{ path: '/gaojing/detail', query: { id: item.id } }"
        >
          <template #title>
            <div class="flex-row fs14">
              <div class="flex-grow-1">设备名称：{{ item.equipmentName }}</div>
              <div class="flex-grow-0">
                <van-tag type="primary">{{ item.alarmLevel }}</van-tag>
              </div>
            </div>
          </template>
          <template #label>
            <div class="flex-col mgt10 fs13">
              <div class="flex-grow-0 flex-row color666">
                <div class="flex-grow-1">告警分类：{{ item.alarmType }}</div>
                <div class="flex-grow-0">告警次数：{{ item.alarmNum }}</div>
              </div>
              <div class="flex-grow-0 flex-row mgt10 color999">
                <div class="flex-grow-1">
                  告警名称： {{ item.equipmentName }}
                </div>
                <div class="flex-grow-0 flex-xy-center time mgl10">
                  {{ parseTime(item.alarmTime, "{y}-{m}-{d}") }}
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-tab>
      <van-tab></van-tab>
      <van-tab></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { getDeviceData } from "@/api/device";
import { getGaojingListByEquil } from "@/api/gaojing";
getGaojingListByEquil;
import Icon1Img from "@/assets/images/icon1.png";
import Header from "@/components/header";

import { Toast, Cell, CellGroup, Tabs, Tab, Icon, Empty } from "vant";
export default {
  name: "DeviceDetail",
  components: {
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    Header,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Empty.name]: Empty,
    [Icon.name]: Icon
  },
  data() {
    return {
      id: "",
      active: 0,
      loading: false,
      icon1Img: Icon1Img,
      jingai:0,
      yushuijin:0,
      wushuijin:0,
      hedaolist:['','','100-2000','6-9','50-500','0-2','＞2','＜10'],
      jingailist:['','','65-115','65-115'],
      yushuijinlist:['','','0-3','','50-500'],
      wushuijinlist:['','','0-3','','＜150','','','',''],
      paramList: [
        // {
        //   mainId: "设备id", //设备/站点id
        //   mainName: "设备名称", //设备/站点名称
        //   mainNum: "设备编号", //设备/站点编号
        //   mainType: "设备类型", //设备/站点类型
        //   createTime: 1659684765000, //创建人
        //   id: "1005136289252507648", //记录id
        //   name: "测点名称", //测点名称
        //   sampleNum: "采样值", //采样值
        //   unit: "单位", //单位
        //   sampleTime: 1659684765000, //采样时间
        //   pk: ""
        // }
      ],
      gaojingList: []
    };
  },
  computed: {},
  created() {
    this.parseTime = this.utils.parseTime;
    const id = this.$route.query.id;
    if (!id) {
      return Toast.fail("参数错误！");
    }
    this.id = id;
    this.getDeviceData();
    this.getGaojingListByEquil();
  },
  mounted() {},
  updated() {},
  methods: {
    getDeviceData() {
      Toast.loading("加载中...");
      const id = this.id;
      getDeviceData({ id: id })
        .then(res => {
          this.paramList = res.data;
          console.log(this.paramList);
          this.jingai=this.paramList[0].mainName.indexOf("井盖")
          this.yushuijin=this.paramList[0].mainName.indexOf("电导率")
          this.wushuijin=this.paramList[0].mainName.indexOf("COD")
          Toast.clear();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getGaojingListByEquil() {
      const id = this.id;
      getGaojingListByEquil({ id: id })
        .then(res => {
          this.gaojingList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.gray{
  color: #999999;
  font-size: 0.35rem;
}
.van-cell__value {
  color: #1989fa;
}
.value{
  display: flex;
  flex-direction: column;
}

.time {
  width: 80px;
}
</style>