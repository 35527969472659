import request from '@/utils/request'

// 获取告警列表
export function getGaojingList(data) {
  return request({
    method: 'post',
    path: 'webapi/waterAlarm/waringList',
    dataType: 'json',
    data
  })
}

// 获取告警详情
export function getGaojingDetail(data) {
  return request({
    method: 'get',
    path: 'webapi/waterAlarm/getWaring',
    data
  })
}


// 获取告警详情
export function getGaojingListByEquil(data) {
  return request({
    method: 'get',
    path: 'webapi/waterAlarm/getWaringByEquil',
    data
  })
}


// 告警处理
export function saveGaojing(data) {
  return request({
    method: 'post',
    path: 'gaojing/saveGaojing',
    data
  })
}