<template>
  <div>
    <van-nav-bar @click-left="back" @click-right="home" :border="false">
      <template #title>
        <span :style="{ color: color }">{{ title }}</span>
      </template>
      <template #left>
        <van-icon name="arrow-left" size="25px" :color="color" />
      </template>
      <template #right>
        <van-icon name="wap-home-o" size="25px" :color="color" />
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
import { NavBar, Icon } from "vant";
export default {
  name: "Header",
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon
  },
  props: {
    title: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: '#FFF'
    }

  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.back(-1);
    },
    home() {
      this.$router.replace('/');
    }
  }
};
</script>
<style lang="scss" scoped>
</style>